import '../../scss/frontend.scss';
import 'aos/dist/aos.css'; // You can also use <link> for styles

require('@fortawesome/fontawesome-free/css/all.min.css');
require('@fortawesome/fontawesome-free/js/all.js');
require('bootstrap');
require('flatpickr');

require('./scripts.js');

// start the Stimulus application
import '../bootstrap';
