import { Tooltip, Popover, ScrollSpy  } from 'bootstrap'
import AOS from 'aos';

const feather = require('feather-icons')

window.addEventListener('DOMContentLoaded', event => {
    // Activate feather
    feather.replace();

    // Activate page animations
    AOS.init({
        // disable: 'mobile',
        duration: 600,
        once: true,
    });

    // Enable tooltips globally
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new Tooltip(tooltipTriggerEl);
    });

    // Enable popovers globally
    var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
    var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
        return new Popover(popoverTriggerEl);
    });

    // Activate Bootstrap scrollspy for the sticky nav component
    // const navStick = document.body.querySelector('#navStick');
    // if (navStick) {
    //     new ScrollSpy(document.body, {
    //         target: '#navStick',
    //         offset: 82,
    //     });
    // }

    // Collapse Navbar
    // Add styling fallback for when a transparent background .navbar-marketing is scrolled
    var navbarCollapse = function() {
        const navbarMarketingTransparentFixed = document.body.querySelector('.navbar-marketing.bg-transparent.fixed-top');
        if (!navbarMarketingTransparentFixed) {
            return;
        }
        if (window.scrollY === 0) {
            navbarMarketingTransparentFixed.classList.remove('navbar-scrolled')
        } else {
            navbarMarketingTransparentFixed.classList.add('navbar-scrolled')
        }

    };
    // Collapse now if page is not at top
    navbarCollapse();
    // Collapse the navbar when page is scrolled
    document.addEventListener('scroll', navbarCollapse);

});
